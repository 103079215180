<template>
  <InfoContainer info="termsOfUse"></InfoContainer>
</template>

<script>
  import InfoContainer from '@/components/info/InfoContainer';
  import { i18nHelper } from '@/utils';

  export default {
    name: 'TermsOfUse',
    metaInfo: {
      title: i18nHelper.getMessage('meta.title.default'),
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: i18nHelper.getMessage('meta.description.default')
        }
      ]
    },
    components: {
      InfoContainer
    }
  };
</script>

<style lang="scss"></style>
